import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const ImageCard = ({ block , openModal}) => {
  return (
    <div id="item" className="card overflow-hidden">
      <div className=" overflow-hidden">
      <GatsbyImage
        image= {block.Image?.localFiles[0].childImageSharp.gatsbyImageData}
        alt={block.Asset_title}
      />
      </div>
        <div id="card_content" className="p-4 lg:p-6 space-y-4 flex flex-wrap flex-col content-between justify-start">
          <h3 id="title" className="max-w-prose tracking-tight ">
            {block.Asset_title}
          </h3>
          <p className="max-w-prose antialiased line-clamp-2">{block.Short_description}</p>
      </div>
    </div>
  )
}

const SideImage = ({ block, index }) => {
  let zigZag =
    index % 2 === 0 ? "sm:flex-row" : "sm:flex-row-reverse sm:space-x-reverse"

  let blockCaption = block.Asset_title ? block.Asset_title : `Step ${index + 1}`

  return (
    <div
      id="item"
      className={`flex flex-col ${zigZag} space-y-4 sm:space-y-0 sm:space-x-16 text-left`}
    >
      <div id="image" className="sm:w-1/2 overflow-hidden">
        <GatsbyImage
        image= {block.Image?.localFiles[0].childImageSharp.gatsbyImageData}
        alt={block.Asset_title}
      />
      </div>
      <div id="header" className="sm:w-1/2 md:pr-16">
        <p className="text-blue-500 uppercase font-bold">{blockCaption}</p>
        <h3 className="pb-3">{block.Short_description}</h3>
        <p>{block.Long_description}</p>
      </div>
    </div>
  )
}

const Plan = ({ block, openModal }) => {
  return (
    <div id="item" className="flex flex-wrap space-y-6 card p-4 lg:p-6 content-between">
      <div className="space-y-3">
        <h3 id="title">{block.Asset_title}</h3>
        <p>{block.Long_description}</p>
      </div>
      <div className="h-16">
      <h4 id="amount">{block.Short_description}</h4>
      <p className="text-sm">{block.Additional_info}</p>
      </div>
      <button className="btn btn-blue-outline w-full shadow-none hover:shadow-none" onClick={openModal}>
        Get Started
      </button>
    </div>
  )
}

const Testimonial = ({ block }) => {
  return (
    <div id="text" className="justify-items-center">
      <h4 className="leading-relaxed lg:px-48 pb-16">{block.Short_description}</h4>
      <p className="font-bold text-white">{block.Asset_title}</p>
      <p className="text-white">{block.Additional_info}</p>
    </div>
  )
}

const Icon = ({ block }) => {
  return (
    <div id="item" className="space-y-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-10 w-10 text-blue-800"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d={block.Symbol}
        />
      </svg>
      <h3 id="title">{block.Asset_title}</h3>
      <p>{block.Short_description}</p>
    </div>
  )
}

const Cta = ({ block, openModal }) => {
  return (
    <div id="action" className="space-y-8 sm:space-y-16">
      <h2 className="leading-relaxed">{block.Short_description}</h2>
      <button className="btn btn-white" onClick={openModal}>Get started</button>
    </div>
  )
}

const Subscribe = ({ block }) => {
  return (
    <div id="content" className="justify-items-center text-center ">
      <form className="space-x-4 space-y-4 sm:space-y-0">
        <input
          type="text"
          className="textfield-rounded focus:outline-none"
          maxlength="200"
          name="Email-Address"
          data-name="Email Address"
          placeholder="Email Address"
          id="Email-Address"
        ></input>
        <input type="submit" className="btn btn-blue" value="Subscribe"></input>
      </form>
    </div>
  )
}

export {
  ImageCard,
  SideImage,
  Plan,
  Testimonial,
  Icon,
  Cta,
  Subscribe,
}