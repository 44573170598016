import React, { useState, Fragment, useRef } from "react"
import { Formik } from "formik"
import Axios from "axios"
import { Alert, Form } from "react-bootstrap"
import { Dialog, Transition } from "@headlessui/react"

const Action = ({
  action,
  isOpen,
  setSubmitting,
  openModal,
  closeModal,
}) => {
  const [response, setResponse] = useState("")
  const [error, setError] = useState("")

  const { fields } = action.form

  const reducer = (accumulator, currentItem) => {
    const newValue = {
      [currentItem.name]: "",
    }
    return { ...accumulator, ...newValue }
  }

  let initialValues = fields.reduce(reducer, [])
  let completeButtonRef = useRef(null)
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={closeModal}
          initialFocus={completeButtonRef}
        >
          <Dialog.Overlay className="fixed inset-0 bg-blue-900 opacity-50" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex flex-row justify-between">
                  <Dialog.Title as="h3">{action.form.title}</Dialog.Title>
                  <button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-gray-400 "
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      onClick={closeModal}
                      alt="close"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                <Dialog.Description className="text-gray-800 pb-4">
                  {action.form.description}
                </Dialog.Description>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, { setSubmitting }) => {
                    let data = Object.keys(initialValues).reduce(
                      (obj, item) => {
                        return {
                          ...obj,
                          [item]: values[item],
                        }
                      },
                      {}
                    )
                    Axios.post(action.form.url, data)
                      .then(function (response) {
                        setError("")
                        setSubmitting(false)
                        setResponse(action.form.messages.success)
                        window.dataLayer = window.dataLayer || []
                        window.dataLayer.push({
                          event: "formSubmissionSuccess",
                          formId: action.form.name,
                        })
                        /*window.fbq = window.fbq || []
                        window.fbq("track", "Lead")
                        setTimeout(() => {
                          handleClose()
                          setResponse("")
                        }, 2000)
                        */
                      })
                      .catch(function (error) {
                        setSubmitting(false)
                        if (!error.data) {
                          setError("Network Error")
                        } else {
                          setError(error.data)
                        }
                      })
                  }}
                >
                  {({ values, handleChange, handleSubmit, isSubmitting }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        {error ? (
                          <Alert variant="danger">
                            <span>
                              Oh snap! Something went wrong!
                              <br />
                              {error}
                              <br />
                              <br />
                              Please complete the form and try again.
                            </span>
                          </Alert>
                        ) : (
                          ""
                        )}

                        {response ? (
                          <Alert variant="success">
                            <span>{response}</span>
                          </Alert>
                        ) : (
                          <>
                            {fields.map((field, index, focus) => {
                              switch (field.type) {
                                case "textarea":
                                  return (
                                    <Form.Group
                                      key={`input_${index}`}
                                      controlId={field.name}
                                    >
                                      <Form.Control
                                        as="textarea"
                                        required={false}
                                        type={field.type}
                                        placeholder={field.label}
                                        name={field.name}
                                        onChange={handleChange}
                                        value={values[field.name]}
                                        className="input-field"
                                      />
                                    </Form.Group>
                                  )
                                case "select":
                                  return (
                                    <Form.Group
                                      key={`input_${index}`}
                                      controlId={field.name}
                                    >
                                      <Form.Control
                                        as="select"
                                        required={true}
                                        placeholder={field.label}
                                        name={field.name}
                                        onChange={handleChange}
                                        value={values[field.name]}
                                        className="input-field"
                                      >
                                        <option value="">{field.label}</option>
                                        {field.options.map((option, index) => (
                                          <option key={`option_${index}`}>
                                            {option}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    </Form.Group>
                                  )
                                default:
                                  return (
                                    <Form.Group
                                      key={`input_${index}`}
                                      controlId={field.name}
                                    >
                                      <Form.Control
                                        required={true}
                                        className="input-field"
                                        type={field.type}
                                        placeholder={field.label}
                                        name={field.name}
                                        onChange={handleChange}
                                        value={values[field.name]}
                                      />
                                    </Form.Group>
                                  )
                              }
                            })}
                            <button
                              className="btn btn-blue w-full"
                              disabled={isSubmitting}
                              ref={completeButtonRef}
                            >
                              Send
                            </button>
                          </>
                        )}
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default Action