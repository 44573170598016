import * as React from "react"
import {
  ImageCard,
  SideImage,
  Plan,
  Testimonial,
  Icon,
  Cta,
  Subscribe,

} from "../components/blocks"

const Section = ({ id, section, openModal, closeModal, isOpen} ) => {

  let blockTypes = {
    "1.🤝 Solutions": ImageCard,
    "1.💪 Services": ImageCard,
    "2.🔢 Steps": SideImage,
    "3.🏷 Plans": Plan,
    "4.💬 Testimonials": Testimonial,
    "5.👍 Benefits": Icon,
    "5.⭐️ Features": Icon,
    "7.📩 Subscribe": Subscribe,
    "8.📣 Cta": Cta,
  }
  let background =
    section.type === "2.🔢 Steps"
      ? "bg-gradient-to-b from-gray-50"
      : section.type === "4.💬 Testimonials"
      ? "my-8 py-8 lg:py-20 space-y-40 bg-gradient-to-r from-gray-800 to-gray-900 text-white"
      : section.type === "8.📣 Cta"
      ? "section bg-gradient-to-r from-blue-500 to-indigo-500 text-white"
      : ""

  //let gridCols =
    //section.type.length % 3 === 0 ? 3 : section.type.length > 4 ? 4 : 3

  let blockLayout =
    section.type === "2.🔢 Steps"
      ? `flex flex-col space-y-8 sm:space-y-40 justify-between`
      : section.type === "4.💬 Testimonials"
      ? "justify-items-center text-center"
      : section.type === "8.📣 Cta"
      ? " justify-items-center text-center"
      : section.type === "7.📩 Subscribe"
      ? "justify-items-center text-center"
      : section.blocks.length % 3 === 0
      ? `grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 sm:gap-6 md:gap-7 lg:gap-8 xl:gap-9 justify-center`
      : section.blocks.length > 3
      ? `grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 sm:gap-6 md:gap-7 lg:gap-8 xl:gap-9 justify-center`
      : `grid grid-cols-1 sm:grid-cols-2 gap-8 sm:gap-6 md:gap-7 lg:gap-8 xl:gap-9 justify-center`

  return (
    <div id={id.toLowerCase().slice(5)} className={background}>
      <div
        id="section_container"
        className="container-default space-y-12 sm:space-y-20 py-12 sm:py-20"
      >
        {section.title || section.description ? (
          <div id="header" className="justify-items-center text-center">
            <h2 className="pb-4">{section.title}</h2>
            <p>{section.description}</p>
          </div>
        ) : (
          ""
        )}

        <div id="content" className={blockLayout}>
          {section.blocks.map((block, index) => {
            //Find section.type value in content.json (ex. "image") and tell me what it means in blockTypes ("ImageCard").
            let BlockType = blockTypes[section.type]
            if (BlockType == null) {
              throw new Error(`Undefined block, type: ${section.type}`)
            }
            return (
              <BlockType
                key={`BlockType_${index}`}
                block={block}
                index={index}
                openModal={openModal}
                closeModal={closeModal}
                isOpen={isOpen}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Section
